import { Common } from '~/models/common';
import type { Socials } from '~/models/socials';

const collection = 'socials';

export const socialsService = {
	fetch: async (): Promise<Socials.Model[]> => {
		const { getSingletonItem } = useDirectusItems();

		return await getSingletonItem<Socials.Model[]>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
			},
		});
	},
};
