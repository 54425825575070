import type { Socials } from '~/models/socials';
import { socialsService } from '~/services/socials';

export const useSocialsState = () => ({
	socialsState: useState<Socials.Model[]>('socialsState', () => []),
	socialsLoaded: useState<boolean>('socialsLoaded', () => false),
	socialsPending: useState<boolean>('socialsPending', () => true),
});

export const useSocials = () => {
	const { socialsState, socialsLoaded, socialsPending } = useSocialsState();

	/**
	 * Fetch socials
	 */
	const fetchSocials = async () => {
		if (socialsLoaded.value) return;

		socialsPending.value = true;

		socialsState.value = await socialsService.fetch();

		socialsLoaded.value = true;
		socialsPending.value = false;
	};

	return {
		socialsState,
		socialsLoaded,
		socialsPending,

		fetchSocials,
	};
};
